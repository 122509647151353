<template>
  <div>
    <b-modal
      id="modal-payment"
      hide-header
      hide-footer
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      title="Large Modal"
      class="custom__modal"
    >
      <ModalHeader
        :left-text="'Kembali'"
        :middle-text="'Pilih Metode Pembayaran'"
        :left-function="directPage"
        :is-rigt-column="true"
        :right-function="postPaymentOther"
        :right-text="'Simpan'"
      />
      <div class="form-payment mt-5 pt-5">
        <b-container>
          <b-row class="justify-content-center align-items-center">
            <b-col cols="8">
              <b-row class="mt-5 pt-5">
                <b-col
                  v-for="(item, index) in payments"
                  :key="index"
                  class="px-0"
                  cols="6"
                >
                  <b-card
                    class="p-2 m-1"
                    no-body
                    style="border-radius: 12px;"
                  >
                    <b-form-checkbox
                      v-model="payment_method"
                      :value="item.value"
                      class="custom-control-success custom__checkbox"
                    >
                      <div
                        role="button"
                        class="d-flex align-items-center"
                      >
                        <div class="d-flex justify-content-center align-items-center rounded-circle image-box box-tempo">
                          <b-img
                            :src="require(`@/assets/images/icons/${item.image}.svg`)"
                            blank-color="#ccc"
                            width="18"
                            alt="placeholder"
                          />
                        </div>
                        <div class="ml-1">
                          <h6 class="text-dark fw-bold-800">
                            {{ item.name }} <span class="text-danger">*</span>
                          </h6>
                          <h6 class="text-dark text-darken-4 fw-bold-500 size12">
                            Pembayaran Langsung
                          </h6>
                        </div>
                      </div>
                    </b-form-checkbox>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BRow, BCol, BContainer, BFormCheckbox, BCard, BImg, BCollapse, BFormDatepicker,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import ModalHeader from '@/components/ModalHeader.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BContainer,
    BFormCheckbox,
    BCard,
    BImg,
    ModalHeader,
    BCollapse,
    BFormDatepicker,
  },
  props: {
    postPaymentOther: {
      type: Function,
    },
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  data() {
    return {
      payment_method: '',
      payment_date: '',
      payments: [
        //  cash,card,transfer,giro,wallet,piutang
        {
          name: 'Tunai',
          value: 'cash',
          image: 'tunai',
          class: 'image-box box-tempo',
        },
        {
          name: 'Transfer Bank',
          value: 'transfer',
          image: 'transfer',
          class: 'image-box box-transfer',
        },
        // {
        //   name: 'Kredit atau Debit',
        //   value: 'card',
        //   image: 'kredit',
        //   class: 'image-box box-kredit',
        // },
        {
          name: 'Giro',
          value: 'giro',
          image: 'giro',
          class: 'image-box box-giro',
        },
        // {
        //   name: 'Deposit',
        //   value: 'wallet',
        //   image: 'tempo',
        //   class: 'image-box box-tunai',
        // },
        // {
        //   name: 'Piutang',
        //   value: 'piutang',
        //   image: 'piutang',
        //   class: 'image-box box-piutang',
        // },
      ],
      formPayload: {},
      customer_supplier_uuid: '',
      isCustomer: false,
      isSupplier: false,
      isLoading: false,
    }
  },
  watch: {
    payment_method(value) {
      // this.formPayload.payment_method = value
      this.$store.commit('payout/setPaymentMethod', value)
    },
  },
  mounted() {
  },
  destroyed() {
    this.payment_method = ''
  },
  methods: {
    directPage() {
      this.$bvModal.hide('modal-payment')
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
.form-payment {
  .custom-checkbox {
    .custom-control-label::after,
    .custom-control-label::before {
      right: 4%;
      top: 35%;
      left: inherit;
    }
    .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: none !important;
    }
    .custom-control-label::before {
      border: none;
    }
  }
  .custom-control-label {
    width: 100%;
  }
  .custom-checkbox.custom-control {
    padding-left: 0;
  }
  .image-box {
    width: 46px;
    height: 46px;
  }
  .card {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
  }
}
#modal-payment {
  .modal-body {
    background: #F6F7FA;
  }
}
</style>
